/* global location */

import { Controller } from '@hotwired/stimulus'
import { TdParagraphConverter } from 'td-paragraph-to-path'

class GlobalController extends Controller {
  connect () {
    this.convertTdiaryFragment()
  }

  /**
   * @returns {HTMLElement}
   */
  postBody () {
    if (!this._postBodyElement) {
      this._postBodyElement = document.querySelector('.post-body')
    }

    return this._postBodyElement
  }

  convertTdiaryFragment () {
    if (this.postBody()) {
      const converted = (new TdParagraphConverter()).convert(location)
      if (converted !== location.toString()) {
        location.href = converted
      }
    }
  }
}

export { GlobalController }
