import 'bootstrap/dist/js/bootstrap.bundle'
import 'iconify-icon'
import * as Turbo from '@hotwired/turbo'
import { Application } from '@hotwired/stimulus'
import { GlobalController } from '../controllers/global_controller'
import { PostBodyController } from '../controllers/post_body_controller'

Turbo.session.drive = false
const app = Application.start()
app.register('global', GlobalController)
app.register('post-body', PostBodyController)
