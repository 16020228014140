import { Controller } from '@hotwired/stimulus'

/**
 * .post-body class 要素内に対して操作を加える controller
 */
export class PostBodyController extends Controller {
  connect () {
    this.addExternalLinkIcons()
  }

  /**
   * @param {string}
   * @returns {NodeList}
   */
  outgoingLinks (scheme) {
    return this.element.querySelectorAll(`a[href^="${scheme}://"]`)
  }

  /**
   * @returns {void}
   */
  addExternalLinkIcons () {
    ;['http', 'https'].forEach((scheme) => {
      this.outgoingLinks(scheme).forEach((link) => {
        const icon = document.createElement('iconify-icon')
        icon.setAttribute('icon', 'mage:external-link')

        const sp = document.createTextNode(' ')
        ;[sp, icon].forEach((e) => {
          link.appendChild(e)
        })
      })
    })
  }
}
